import './App.css';
import { Pricing } from './components/Pricing'
import { Header } from './components/Header'
import Popup from 'reactjs-popup';
import React, { useState, useEffect, useId, useRef } from 'react';
import { useAnimation, motion, useMotionTemplate, useMotionValue, useScroll, useTransform } from "framer-motion";
import { useInView } from "react-intersection-observer";


import {
  ArrowPathIcon,
  ChevronRightIcon,
  CloudArrowUpIcon,
  Cog6ToothIcon,
  FingerPrintIcon,
  LockClosedIcon,
  ServerIcon,
} from '@heroicons/react/20/solid'
import { BoltIcon, CalendarDaysIcon, HandRaisedIcon } from '@heroicons/react/24/outline'

const primaryFeatures = [
  {
    name: 'Security monitoring',
    description:
      'Get notified immediately when something critical exists on your apps. We monitor your infra 24/7 so you don’t have to.',
    href: '#',
    icon: BoltIcon,
    pattern: {
      y: 16,
      squares: [
        [0, 1],
        [1, 3],
      ],
    },
  },
  {
    name: 'Deploy anywhere',
    description:
      'With support for all major cloud and repository providers, you can deploy your app anywhere you want. Your infrastructure, your rules.',
    href: '#',
    icon: ServerIcon,
    pattern: {
      y: 16,
      squares: [
        [0, 1],
        [1, 3],
      ],
    },
  },
  {
    name: 'Task scheduling',
    description:
      'Need to run automated tests every day? We’ve got you covered. Simply set a schedule and we’ll do the rest.',
    href: '#',
    icon: CalendarDaysIcon,
    pattern: {
      y: 16,
      squares: [
        [0, 1],
        [1, 3],
      ],
    },
  },
]
const secondaryFeatures = [
  {
    name: 'Push to deploy.',
    description: 'Integrate security into you workflow. Just push your code, and we do the rest.',
    icon: CloudArrowUpIcon,
  },
  {
    name: 'Security at every layer.',
    description: 'All your data and requests are stored directly on our servers, with highly secure, state-of-the-art protocols.',
    icon: LockClosedIcon,
  },
  {
    name: 'Simple queues.',
    description: 'Schedule tasks to run at specific times, or use our API to trigger a task from anywhere.',
    icon: ArrowPathIcon,
  },
  {
    name: 'Advanced tooling.',
    description: 'Scan your apps for security vulnerabilities and get notified immediately when something critical exists.',
    icon: FingerPrintIcon,
  },
  {
    name: 'Powerful API.',
    description: 'Run tests programatically, or use our API to integrate security into your existing workflow.',
    icon: Cog6ToothIcon,
  },
  {
    name: 'Customizable.',
    description: 'Only run the tests you need, when you need them. We support all major security tests out of the box.',
    icon: BoltIcon,
  },
]
const stats = [
  { id: 2, name: 'Daily requests', value: '19,000+' },
  { id: 3, name: 'Uptime guarantee', value: '99.9%' },
  { id: 4, name: 'Tests deployed', value: '100,000+' },
]
const footerNavigation = {
  support: [
    { name: 'Pricing', href: '#pricing' },
    { name: 'Documentation', href: 'https://docs.lunchcat.dev' },
  ],
  company: [
    { name: 'Blog', href: 'https://blog.lunchcat.dev' },
    { name: 'Careers', href: 'https://jobs.lunchcat.dev' }, ,
  ],
  legal: [
    { name: 'Privacy', href: '#' },
    { name: 'Terms', href: '#' },
  ],
  social: [
    {
      name: 'Twitter',
      href: 'https://twitter.com/joinlunchcat',
      icon: (props) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
        </svg>
      ),
    },
    {
      name: 'Discord',
      href: 'https://discord.gg/uzQv4YbJ8W',
      icon: (props) => (
        <span class="[&>svg]:h-7 [&>svg]:w-7 ">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 600" fill="currentColor" {...props}>
            <path
              d="M524.5 69.8a1.5 1.5 0 0 0 -.8-.7A485.1 485.1 0 0 0 404.1 32a1.8 1.8 0 0 0 -1.9 .9 337.5 337.5 0 0 0 -14.9 30.6 447.8 447.8 0 0 0 -134.4 0 309.5 309.5 0 0 0 -15.1-30.6 1.9 1.9 0 0 0 -1.9-.9A483.7 483.7 0 0 0 116.1 69.1a1.7 1.7 0 0 0 -.8 .7C39.1 183.7 18.2 294.7 28.4 404.4a2 2 0 0 0 .8 1.4A487.7 487.7 0 0 0 176 479.9a1.9 1.9 0 0 0 2.1-.7A348.2 348.2 0 0 0 208.1 430.4a1.9 1.9 0 0 0 -1-2.6 321.2 321.2 0 0 1 -45.9-21.9 1.9 1.9 0 0 1 -.2-3.1c3.1-2.3 6.2-4.7 9.1-7.1a1.8 1.8 0 0 1 1.9-.3c96.2 43.9 200.4 43.9 295.5 0a1.8 1.8 0 0 1 1.9 .2c2.9 2.4 6 4.9 9.1 7.2a1.9 1.9 0 0 1 -.2 3.1 301.4 301.4 0 0 1 -45.9 21.8 1.9 1.9 0 0 0 -1 2.6 391.1 391.1 0 0 0 30 48.8 1.9 1.9 0 0 0 2.1 .7A486 486 0 0 0 610.7 405.7a1.9 1.9 0 0 0 .8-1.4C623.7 277.6 590.9 167.5 524.5 69.8zM222.5 337.6c-29 0-52.8-26.6-52.8-59.2S193.1 219.1 222.5 219.1c29.7 0 53.3 26.8 52.8 59.2C275.3 311 251.9 337.6 222.5 337.6zm195.4 0c-29 0-52.8-26.6-52.8-59.2S388.4 219.1 417.9 219.1c29.7 0 53.3 26.8 52.8 59.2C470.7 311 447.5 337.6 417.9 337.6z" />
          </svg>
        </span>
      ),
    },
    {
      name: 'GitHub',
      href: 'https://github.com/lunchcat',
      icon: (props) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path
            fillRule="evenodd"
            d="M12 2C6.477 2 2 6.484 2 12.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0112 6.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.202 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.943.359.309.678.92.678 1.855 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0022 12.017C22 6.484 17.522 2 12 2z"
            clipRule="evenodd"
          />
        </svg>
      ),
    },
  ],
}

function Waitlist() {
  const [isSubscribed, setIsSubscribed] = useState(false); // State to track subscription status
  const [isError, setIsError] = useState(false); // State to hold the email input

  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent the default form submission
    const email = event.target.elements['email'].value; // Get the email from the form

    const url = "https://waitlist.lunchcat.dev/waitlist"; // URL to send the data to

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        mode: 'no-cors',
        body: JSON.stringify({ email: email, status: "subscribe" }), // Convert the JavaScript object to a JSON string
      });

      if (response.ok) {
        console.log('Subscription successful');
        setIsSubscribed(true);
        // Handle success here, e.g., show a success message
      } else {
        console.error('Subscription failed');
        setIsError(true);
        // Handle errors here, e.g., show an error message
      }
    } catch (error) {
      console.error('Network error:', error);
      setIsError(true);
      // Handle network errors here
    }
  };


  return (
    <div className="relative isolate overflow-hidden bg-zinc-950 py-16 sm:py-24 lg:py-32 rounded-lg">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-2">
          <div className="max-w-xl lg:max-w-lg">
            <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">Join our waitlist.</h2>
            <p className="mt-4 text-lg leading-8 text-gray-300">
              Be the first to know when we launch. We’ll send you an email with all the details once things are ready.
            </p>
            <div className="mt-6 flex max-w-md gap-x-4">
            <form onSubmit={handleSubmit}>
              <label htmlFor="email-address" className="sr-only">
                Email address
              </label>
              <input
                id="email-address"
                name="email"
                type="email"
                autoComplete="email"
                required
                className="min-w-0 flex-auto rounded-l-md border-0 bg-white/5 px-3.5 py-2 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                placeholder="Enter your email"
              />
              <button
                type="submit"
                className="flex-none rounded-r-md bg-indigo-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
              >
                Subscribe
              </button>
              </form>
            </div>
            {isSubscribed && (
        <p className="text-green-500 mt-3">Thanks for subscribing!</p> // Conditional rendering of success message
      )}
            {isError && (
        <p className="text-green-500 mt-3">Thanks for subscribing!</p> // Conditional rendering of error message
      )}
          </div>
        </div>
      </div>
      <div className="absolute left-1/2 top-0 -z-10 -translate-x-1/2 blur-3xl xl:-top-6" aria-hidden="true">
        <div
          className="aspect-[1155/678] w-[72.1875rem] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30"
          style={{
            clipPath:
              'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
          }}
        />
      </div>
    </div>
  )
}

function GridPattern({ width, height, x, y, squares, ...props }) {
  let patternId = useId()

  return (
    <svg aria-hidden="true" {...props}>
      <defs>
        <pattern
          id={patternId}
          width={width}
          height={height}
          patternUnits="userSpaceOnUse"
          x={x}
          y={y}
        >
          <path d={`M.5 ${height}V.5H${width}`} fill="none" />
        </pattern>
      </defs>
      <rect
        width="100%"
        height="100%"
        strokeWidth={0}
        fill={`url(#${patternId})`}
      />
      {squares && (
        <svg x={x} y={y} className="overflow-visible">
          {squares.map(([x, y]) => (
            <rect
              strokeWidth="0"
              key={`${x}-${y}`}
              width={width + 1}
              height={height + 1}
              x={x * width}
              y={y * height}
            />
          ))}
        </svg>
      )}
    </svg>
  )
}

function ResourcePattern({ mouseX, mouseY, ...gridProps }) {
  let maskImage = useMotionTemplate`radial-gradient(180px at ${mouseX}px ${mouseY}px, white, transparent)`
  let style = { maskImage, WebkitMaskImage: maskImage }

  return (
    <div className="pointer-events-none">
      <div className="absolute inset-0 rounded-2xl transition duration-300 [mask-image:linear-gradient(white,transparent)] group-hover:opacity-50">
        <GridPattern
          width={72}
          height={56}
          x="50%"
          className="absolute inset-x-0 inset-y-[-30%] h-[160%] w-full skew-y-[-18deg] fill-black/[0.02] stroke-black/5 fill-white/1 stroke-white/2.5"
          {...gridProps}
        />
      </div>
      <motion.div
        className="absolute inset-0 rounded-2xl bg-gradient-to-r opacity-0 transition duration-300 group-hover:opacity-100 from-[#202D2E] to-[#a34ce6]"
        style={style}
      />
      <motion.div
        className="absolute inset-0 rounded-2xl opacity-0 mix-blend-overlay transition duration-300 group-hover:opacity-100"
        style={style}
      >
        <GridPattern
          width={72}
          height={56}
          x="50%"
          className="absolute inset-x-0 inset-y-[-30%] h-[160%] w-full skew-y-[-18deg] fill-black/50 stroke-black/70 dark:fill-white/2.5 dark:stroke-white/10"
          {...gridProps}
        />
      </motion.div>
    </div>
  )
}

function Resource({ resource }) {
  let mouseX = useMotionValue(0)
  let mouseY = useMotionValue(0)

  function onMouseMove({ currentTarget, clientX, clientY }) {
    let { left, top } = currentTarget.getBoundingClientRect()
    mouseX.set(clientX - left)
    mouseY.set(clientY - top)
  }

  return (
    <div
      key={resource.href}
      onMouseMove={onMouseMove}
      className="group relative flex rounded-2xl transition-shadow hover:shadow-md bg-white/2.5 hover:shadow-black/5"
    >
      <ResourcePattern {...resource.pattern} mouseX={mouseX} mouseY={mouseY} />
      <div className="absolute inset-0 rounded-2xl ring-1 ring-inset ring-white/10 group-hover:ring-white/20" />
      <div className="relative rounded-2xl px-4 pb-8 pt-16">
      <resource.icon className="h-6 w-6 text-white" aria-hidden="true" />
        <h3 className="mt-4 text-sm font-semibold leading-7 text-white">
          <a href={resource.href}>
            <span className="absolute inset-0 rounded-2xl" />
            {resource.name}
          </a>
        </h3>
        <p className="mt-1 text-sm text-zinc-400">
          {resource.description}
        </p>
      </div>
    </div>
  )
}

const featVariants = {
  visible: { opacity: 1, scale: 1, transition: { duration: 1 }, y: 0 },
  hidden: { opacity: 0, scale: 1, y: 20 }
};

function Features() {
  const controls = useAnimation();
  const [ref, inView] = useInView();
  let mouseX = useMotionValue(0)
  let mouseY = useMotionValue(0)

  function onMouseMove({ currentTarget, clientX, clientY }) {
    let { left, top } = currentTarget.getBoundingClientRect()
    mouseX.set(clientX - left)
    mouseY.set(clientY - top)
  }
  useEffect(() => {
    onscroll = () => {
    if (inView) {
      controls.start("visible");
    }
  }
  }, [controls, inView]);
  return (
    <motion.div
    ref={ref}
    animate={controls}
    initial="hidden"
    variants={featVariants}
    className="square"
  >
      <div className="mx-auto mb-40 max-w-7xl px-6 lg:px-8"
      onMouseMove={onMouseMove}>
          <div className="mx-auto max-w-2xl text-center">
              <h2 className="text-base font-semibold leading-7 text-indigo-400">Deploy without worries</h2>
              <p className="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">
                  Everything you need to test your app.
              </p>
              <p className="mt-6 text-lg leading-8 text-gray-300">
                  Run industry-standard security tests on every commit, or anytime, all directly through the cloud.
              </p>
          </div>
          <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-none">
              <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-3">
                  {primaryFeatures.map((feature) => (
                    <Resource key={feature.href} resource={feature} />
                  ))}
              </dl>
          </div>
      </div>
      </motion.div>
  )
}

const HorizontalScrollCarousel = () => {
  const targetRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: targetRef,
  });

  const x = useTransform(scrollYProgress, [0, 1], ["1%", "-95%"]);

  return (
    <section ref={targetRef} className="-mt-96 relative h-[300vh]">
      <div className="sticky top-0 flex h-screen items-center overflow-hidden mt-52 lg:mt-0">
        <motion.div style={{ x }} className="flex gap-36 pr-10">
        {secondaryFeatures.map((feature) => (
                    <div
                    key={feature.href}
                    className="w-96 group relative flex align-middle rounded-2xl transition-all transition-shadow hover:shadow-md bg-white/2.5 hover:shadow-black/5"
                  >
                    
                      <h3 className="ring bg-indigo-500/10 ring-indigo-500/20 pt-5 pb-5 pr-2 pl-2 rounded-full  text-sm font-semibold text-center text-white flex flex-row gap-2 align-middle">
                      <feature.icon className=" h-10 w-10 text-indigo-500 text-center align-middle" aria-hidden="true" /> 
                        <a href={feature.href}>
                          
                          <p className='text-center text-3xl'>{feature.name}</p>
                        </a>
                      </h3>
                  </div>
              ))}
        </motion.div>
      </div>
    </section>
  );
};

const cards = [
  {
    url: "/imgs/abstract/1.jpg",
    title: "Title 1",
    id: 1,
  },
  {
    url: "/imgs/abstract/2.jpg",
    title: "Title 2",
    id: 2,
  },
  {
    url: "/imgs/abstract/3.jpg",
    title: "Title 3",
    id: 3,
  },
  {
    url: "/imgs/abstract/4.jpg",
    title: "Title 4",
    id: 4,
  },
  {
    url: "/imgs/abstract/5.jpg",
    title: "Title 5",
    id: 5,
  },
  {
    url: "/imgs/abstract/6.jpg",
    title: "Title 6",
    id: 6,
  },
  {
    url: "/imgs/abstract/7.jpg",
    title: "Title 7",
    id: 7,
  },
];

const Card = ({ card }) => {
  return (
    <div
      key={card.id}
      className="group relative h-[450px] w-[450px] overflow-hidden bg-neutral-200"
    >
      <div
        style={{
          backgroundImage: `url(${card.url})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
        className="absolute inset-0 z-0 transition-transform duration-300 group-hover:scale-110"
      ></div>
      <div className="absolute inset-0 z-10 grid place-content-center">
        <p className="bg-gradient-to-br from-white/20 to-white/0 p-8 text-6xl font-black uppercase text-white backdrop-blur-lg">
          {card.title}
        </p>
      </div>
    </div>
  );
};

function FeaturesExp() {
  const controls = useAnimation();
  const [ref, inView] = useInView();
  let mouseX = useMotionValue(0)
  let mouseY = useMotionValue(0)

  function onMouseMove({ currentTarget, clientX, clientY }) {
    let { left, top } = currentTarget.getBoundingClientRect()
    mouseX.set(clientX - left)
    mouseY.set(clientY - top)
  }
  useEffect(() => {
    onscroll = () => {
    if (inView) {
      controls.start("visible");
    }
  }
  }, [controls, inView]);
  return (
    <motion.div
    ref={ref}
    animate={controls}
    initial="hidden"
    variants={featVariants}
    className="-mt-72 square"
  >
              <div className="relative overflow-hidden pt-16">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
              <img
                src="/appscreenshot.png"
                alt="App screenshot"
                className="mb-[-12%] rounded-xl shadow-2xl ring-1 ring-white/10"
                width={2432}
                height={1442}
              />
              <div className="relative" aria-hidden="true">
                <div className="absolute -inset-x-20 bottom-0 bg-gradient-to-t from-zinc-950 pt-[7%]" />
              </div>
            </div>
          </div>
          <div className="mx-auto max-w-7xl px-6 sm:mt-20 md:mt-24 lg:px-8">
            <dl className="mx-auto grid max-w-2xl grid-cols-1 gap-x-6 gap-y-10 text-base leading-7 text-gray-300 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3 lg:gap-x-8 lg:gap-y-16">
              {secondaryFeatures.map((feature) => (
                <div key={feature.name} className="relative pl-9 ">
                  <dt className="inline font-semibold text-white">
                    <feature.icon className="absolute left-1 top-1 h-5 w-5 text-indigo-500" aria-hidden="true" />
                    {feature.name}
                  </dt>{' '}
                  <dd className="inline">{feature.description}</dd>
                </div>
              ))}
            </dl>
          </div>
  </motion.div>
  )
}

function FeaturesAlt() {
  const controls = useAnimation();
  const [ref, inView] = useInView();
  let mouseX = useMotionValue(0)
  let mouseY = useMotionValue(0)

  function onMouseMove({ currentTarget, clientX, clientY }) {
    let { left, top } = currentTarget.getBoundingClientRect()
    mouseX.set(clientX - left)
    mouseY.set(clientY - top)
  }
  useEffect(() => {
    onscroll = () => {
    if (inView) {
      controls.start("visible");
    }
  }
  }, [controls, inView]);
  return (
    <motion.div
    ref={ref}
    animate={controls}
    initial="hidden"
    variants={featVariants}
    className="square"
  >
            <div className="mt-32">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="mx-auto max-w-2xl sm:text-center">
              <h2 className="text-base font-semibold leading-7 text-indigo-400">Everything you need</h2>
              <p className="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">No infrastructure? No problem.</p>
              <p className="mt-6 text-lg leading-8 text-gray-300">
                Automatically run and scale your security tests on every commit, all directly through the cloud.
              </p>
            </div>
          </div>
          <HorizontalScrollCarousel />
          <FeaturesExp />
        </div>
  </motion.div>
  )
}

export default function App() {
  return (
    <div className="bg-zinc-950">
      <Header />
      <main className='h-full'>
        {/* Hero section */}
        <div className="relative isolate overflow-hidden">
          <svg
            className="absolute inset-0 -z-10 h-full w-full stroke-white/10 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]"
            aria-hidden="true"
          >
            <defs>
              <pattern
                id="983e3e4c-de6d-4c3f-8d64-b9761d1534cc"
                width={200}
                height={200}
                x="50%"
                y={-1}
                patternUnits="userSpaceOnUse"
              >
                <path d="M.5 200V.5H200" fill="none" />
              </pattern>
            </defs>
            <svg x="50%" y={-1} className="overflow-visible fill-gray-800/20">
              <path
                d="M-200 0h201v201h-201Z M600 0h201v201h-201Z M-400 600h201v201h-201Z M200 800h201v201h-201Z"
                strokeWidth={0}
              />
            </svg>
            <rect width="100%" height="100%" strokeWidth={0} fill="url(#983e3e4c-de6d-4c3f-8d64-b9761d1534cc)" />
          </svg>
          <div
            className="absolute left-[calc(50%-4rem)] top-10 -z-10 transform-gpu blur-3xl sm:left-[calc(50%-18rem)] lg:left-48 lg:top-[calc(50%-30rem)] xl:left-[calc(50%-24rem)]"
            aria-hidden="true"
            id="hero-cloud"
          >
            <div
              className="aspect-[1108/632] w-[69.25rem] bg-gradient-to-r from-[#a34ce6] to-[#4f46e5] opacity-20"
              style={{
                clipPath:
                  'polygon(73.6% 51.7%, 91.7% 11.8%, 100% 46.4%, 97.4% 82.2%, 92.5% 84.9%, 75.7% 64%, 55.3% 47.5%, 46.5% 49.4%, 45% 62.9%, 50.3% 87.2%, 21.3% 64.1%, 0.1% 100%, 5.4% 51.1%, 21.4% 63.9%, 58.9% 0.2%, 73.6% 51.7%)',
              }}
            />
          </div>
          <div className="h-full mx-auto max-w-7xl px-6 pb-24 pt-10 sm:pb-40 lg:flex lg:px-8 lg:pt-40">
            <div className="mx-auto max-w-2xl flex-shrink-0 lg:mx-0 lg:max-w-xl lg:pt-8">
              {/* <div className="mt-24 sm:mt-32 lg:mt-16">
                <a href="#" className="inline-flex space-x-6">
                  <span className="rounded-full bg-orange-500/10 px-3 py-1 text-sm font-semibold leading-6 text-orange-400 ring-1 ring-inset ring-indigo-500/20">
                    Soon
                  </span>
                  <span className="inline-flex items-center space-x-2 text-sm font-medium leading-6 text-gray-300">
                    <span>Cloud v1.0 is about to ship</span>
                    <ChevronRightIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
                  </span>
                </a>
              </div> */}
              <img src="/vtuberlogo.png" alt="Lunchcat logo" className="mt-10 text-4xl font-bold tracking-tight text-white sm:text-6xl mx-auto" />
              {/*<h1 className="mt-10 text-4xl font-bold tracking-tight text-white sm:text-6xl">
                Focus less on security. Ship <span className='moving-gradient'>instantly.</span>
              </h1> */}
              <p className="mt-6 text-lg leading-8 text-gray-300">
                The AI-Powered security tool you never thought you needed. We monitor your apps 24/7 so you don’t have to.<br />
                Stay tuned for our upcoming launch.
              </p>
              <div className="mt-10 flex items-center gap-x-6">
                <Popup trigger={<a
                  href="#"
                  className="rounded-md bg-indigo-500/30 border border-indigo-500/40 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400/30 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-400"
                >
                  Join waitlist
                </a>}
                modal
                nested>
                  <Waitlist />
                </Popup>
                <a href="https://x.com/joinlunchcat" className="text-sm font-semibold leading-6 text-white">
                  Follow us on X <span aria-hidden="true">→</span>
                </a>
              </div>
            </div>
            <div className="mx-auto mt-16 flex max-w-2xl sm:mt-24 lg:ml-10 lg:mr-0 lg:mt-0 lg:max-w-none lg:flex-none xl:ml-32">
              <div className="max-w-3xl flex-none sm:max-w-5xl lg:max-w-none">
                <img
                  src="/product.png"
                  alt="App screenshot"
                  width={2432}
                  height={1442}
                  className="w-[76rem] rounded-md bg-white/5 shadow-2xl ring-1 ring-white/10"
                />
              </div>
            </div>
          </div>
        </div>

        {/* <div className="mx-auto mt-8 max-w-7xl px-6 sm:mt-16 lg:px-8 mb-20">
          <h2 className="text-center text-lg font-semibold leading-8 text-white">
            The world’s most innovative companies trust us
          </h2>
          <div className="mx-auto mt-10 grid max-w-lg grid-cols-4 items-center gap-x-8 gap-y-10 sm:max-w-xl sm:grid-cols-6 sm:gap-x-10 lg:mx-0 lg:max-w-none lg:grid-cols-5">
            <img
              className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
              src="midday.svg"
              alt="Transistor"
              width={158}
              height={48}
            />
            
            <img
              className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
              src="https://tailwindui.com/img/logos/158x48/reform-logo-white.svg"
              alt="Reform"
              width={158}
              height={48}
            />
            <img
              className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
              src="https://tailwindui.com/img/logos/158x48/tuple-logo-white.svg"
              alt="Tuple"
              width={158}
              height={48}
            />
            <img
              className="col-span-2 max-h-12 w-full object-contain sm:col-start-2 lg:col-span-1"
              src="https://tailwindui.com/img/logos/158x48/savvycal-logo-white.svg"
              alt="SavvyCal"
              width={158}
              height={48}
            />
            <img
              className="col-span-2 col-start-2 max-h-12 w-full object-contain sm:col-start-auto lg:col-span-1"
              src="https://tailwindui.com/img/logos/158x48/statamic-logo-white.svg"
              alt="Statamic"
              width={158}
              height={48}
            />
          </div>
        </div>*/}

        
     <Features />
     <FeaturesAlt />

        {/* Stats 
        <div className="mx-auto mt-32 max-w-7xl px-6 sm:mt-56 lg:px-8">
          <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-xl">
            <h2 className="text-base font-semibold leading-8 text-indigo-400">Our track record</h2>
            <p className="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">
              Trusted by hundreds of developers&nbsp;worldwide
            </p>
            <p className="mt-6 text-lg leading-8 text-gray-300">
              People use lunchcat to secure and deploy their software, all over the world.
            </p>
          </div>
          <dl className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-10 text-white sm:mt-20 sm:grid-cols-2 sm:gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-4">
            {stats.map((stat) => (
              <div key={stat.id} className="flex flex-col gap-y-3 border-l border-white/10 pl-6">
                <dt className="text-sm leading-6">{stat.name}</dt>
                <dd className="order-first text-3xl font-semibold tracking-tight">{stat.value}</dd>
              </div>
            ))}
          </dl>
        </div> */}

        <div className="relative isolate mt-32 px-6 py-32 sm:mt-56 sm:py-40 lg:px-8">
          <svg
            className="absolute inset-0 -z-10 h-full w-full stroke-white/10 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]"
            aria-hidden="true"
          >
            <defs>
              <pattern
                id="1d4240dd-898f-445f-932d-e2872fd12de3"
                width={200}
                height={200}
                x="50%"
                y={0}
                patternUnits="userSpaceOnUse"
              >
                <path d="M.5 200V.5H200" fill="none" />
              </pattern>
            </defs>
            <svg x="50%" y={0} className="overflow-visible fill-gray-800/20">
              <path
                d="M-200 0h201v201h-201Z M600 0h201v201h-201Z M-400 600h201v201h-201Z M200 800h201v201h-201Z"
                strokeWidth={0}
              />
            </svg>
            <rect width="100%" height="100%" strokeWidth={0} fill="url(#1d4240dd-898f-445f-932d-e2872fd12de3)" />
          </svg>
          <div className="mx-auto max-w-2xl text-center">
            <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
              Ready to join us?
              <br />
              Join our waitlist.
            </h2>
            <p className="mx-auto mt-6 max-w-xl text-lg leading-8 text-gray-300">
              Start using lunchcat as soon as it's ready, and keep your apps secure.
            </p>
            <div className="mt-10 flex items-center justify-center gap-x-6">
            <Popup trigger={<a
                  href="#"
                  className="rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
                >
                  Join waitlist
                </a>}
                modal
                nested>
                  <Waitlist />
                </Popup>
              <a href="https://x.com/joinlunchcat" className="text-sm font-semibold leading-6 text-white">
                Follow us on X <span aria-hidden="true">→</span>
              </a>
            </div>
          </div>
        </div>
      </main>

      {/* Footer */}
      <footer aria-labelledby="footer-heading" className="relative">
        <h2 id="footer-heading" className="sr-only">
          Footer
        </h2>
        <div className="mx-auto max-w-7xl px-6 pb-8 pt-4 lg:px-8">
          <div className="border-t border-white/10 pt-8 md:flex md:items-center md:justify-between">
            <div className="flex space-x-6 md:order-2">
              {footerNavigation.social.map((item) => (
                <a key={item.name} href={item.href} className="text-gray-500 hover:text-gray-400">
                  <span className="sr-only">{item.name}</span>
                  <item.icon className="h-6 w-6" aria-hidden="true" />
                </a>
              ))}
            </div>
            <p className="mt-8 text-s align-bottom leading-5 text-gray-400 md:order-1 md:mt-0">
              &copy; 2023-2024 lunchcat, Inc. All rights reserved.
            </p>
          </div>
        </div>
      </footer>
    </div>
  )
}